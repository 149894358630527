import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from '@emotion/styled';
import kebabcase from 'lodash.kebabcase';
import Layout from '../components/layout';
import Pagination from '../components/pagination';
import SEO from '../components/seo';
import SocialFooter from '../components/social-footer';
import theme from '../utils/theme';

const Wrapper = styled.div`
  width: 100%;
`;

const Content = styled.div``;

const MarkedHeader = styled.h1``;

const HeaderDate = styled.h3`
  margin-top: 10px;
  color: #606060;
`;

const HeaderTags = styled.div`
  font-family: 'Open Sans Condensed';
  font-weight: bold;
  font-size: 0.8rem;
  margin-bottom: 1rem;

  a {
    color: ${theme.light.fgColor};
    background-image: none;
  }

  a:hover {
    color: ${theme.dark.greenLink};
    text-decoration: underline;
  }
`;

// STYLE THE TAGS INSIDE THE MARKDOWN HERE
const MarkdownContent = styled.div`
  .gatsby-resp-image-figcaption {
    font-size: 0.7em;
    text-align: center;
    margin-top: 0.9em;
  }

  a {
    text-shadow: none;
    background-image: none;
    text-decoration: none;
    position: relative;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    & code.language-text {
      padding: 0;
      margin: 0;
      text-shadow: none;
      font-size: 0.85em;
      background-color: rgba(27, 31, 35, 0.05);
      border-radius: 3px;
      font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
      color: #b9002c;
    }
  }
`;

export default props => {
  const post = props.data.markdownRemark;
  const { path: leftLink, title: leftText } = props.pageContext.prev || {};
  const { path: rightLink, title: rightText } = props.pageContext.next || {};

  const tags = props.pageContext.tags.map((tag, i) => {
    let elem = (
      <Link key={i} title={tag} to={`/tags/${kebabcase(tag)}`}>
        {tag}
      </Link>
    );

    if (i > 0) {
      elem = <React.Fragment key={i}>, {elem}</React.Fragment>;
    }

    return elem;
  });

  const summary = post.frontmatter.summary || post.excerpt;

  return (
    <Layout
      main={
        <Wrapper>
          <SEO
            type="article"
            title={post.frontmatter.title}
            description={summary}
            url={post.frontmatter.path}
            published={post.frontmatter.date}
            keywords={props.pageContext.tags}
            featuredImage={post.frontmatter.featuredImage}
            schema={post.frontmatter.schema}
          />
          <Content>
            <MarkedHeader>{post.frontmatter.title}</MarkedHeader>
            <HeaderDate>
              {post.frontmatter.date} - {post.fields.readingTime.text}
            </HeaderDate>
            {tags && tags.length > 0 && (
              <HeaderTags>Tagged under: {tags}</HeaderTags>
            )}
            <MarkdownContent dangerouslySetInnerHTML={{ __html: post.html }} />
            <SocialFooter
              title={post.frontmatter.title}
              summary={summary}
              path={post.frontmatter.path}
              tags={props.pageContext.tags}
            />
            <Pagination
              leftLink={leftLink}
              leftText={leftText}
              rightLink={rightLink}
              rightText={rightText}
            />
          </Content>
        </Wrapper>
      }
    ></Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        summary
        schema
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`;
