import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, Link, graphql } from 'gatsby';
import styled from '@emotion/styled';
import kebabcase from 'lodash.kebabcase';
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  RedditIcon,
  LinkedinIcon,
} from 'react-share';
import portraitURL from '../images/portrait.png';
import theme from '../utils/theme';
import mq from '../utils/mq';
import { Tag } from '../components/tagcloud';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const Frame = styled.div`
  color: ${theme.dark.fgColor};
  background-color: ${theme.dark.bgColor};
  padding: 1rem;
  margin-top: 1rem;

  a {
    text-shadow: none;
    background-image: none;
  }
`;

const Title = styled.h3`
  margin-top: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const Portrait = styled.img`
  flex: 0 0 auto;
  display: none;

  width: 54px;
  height: 54px;

  ${mq.bpw1} {
    display: block;
  }

  ${mq.bpw3} {
    width: 64px;
    height: 64px;
  }
`;

const Info = styled.div`
  flex: 1 1 auto;
  margin-left: 0;

  ${mq.bpw1} {
    margin-left: 1.2rem;
  }

  p {
    font-size: 0.9rem;
    margin-bottom: 0.1rem;
    word-break: break-word;

    a {
      margin-left: 5px;
    }
  }

  p.descriptionLong {
    display: none;
    font-size: 0.7rem;
    padding-right: 1.5rem;

    ${mq.bpw3} {
      display: block;
    }
  }

  p.descriptionShort {
    display: block;

    ${mq.bpw3} {
      display: none;
    }
  }
`;

const TagList = styled.div`
  flex: 1 0 auto;
  display: none;

  ${mq.bpw3} {
    display: block;
  }

  h4 {
    margin: 0;
    color: ${theme.dark.fgColor};
  }

  a {
    display: block;
    margin-left: 0;
    padding-left: 0;
  }
`;

const ShareText = styled.div`
  font-size: 0.8rem;
  margin-bottom: 1.5rem;

  ${mq.bpw1} {
    display: none;
  }

  strong {
    display: block;
    position: relative;
    top: 0.4rem;
  }

  button {
    font-size: 0.8rem !important;
    margin-left: 0.2rem;

    &:hover {
      color: ${theme.dark.greenLink} !important;
      text-decoration: underline;
    }
  }
`;

const ShareIcons = styled.div`
  display: none;
  margin-bottom: 1.5rem;

  ${mq.bpw1} {
    display: block;
  }

  strong {
    display: block;
  }
`;

const SocialFooter = props => {
  const tags = props.tags.slice(0, 3).map((tag, i) => (
    <Tag key={i} to={`/tags/${kebabcase(tag)}`}>
      <span className="tag-text">{tag}</span>
    </Tag>
  ));

  return (
    <StaticQuery
      query={graphql`
        query socialFooterTitle {
          site {
            siteMetadata {
              title
              author
              description
              siteUrl
            }
          }
        }
      `}
      render={data => {
        const postUrl = data.site.siteMetadata.siteUrl + props.path;
        const size = 32;

        return (
          <Wrapper>
            <ShareText>
              <strong>Share:</strong>
              <RedditShareButton url={postUrl} title={props.title}>
                reddit
              </RedditShareButton>
              {' ·'}
              <TwitterShareButton url={postUrl} title={props.title}>
                twitter
              </TwitterShareButton>
              {' ·'}
              <FacebookShareButton url={postUrl} quote={props.summary}>
                facebook
              </FacebookShareButton>
              {' ·'}
              <LinkedinShareButton
                url={postUrl}
                title={props.title}
                summary={props.summary}
                source={data.site.siteMetadata.title}
              >
                linkedin
              </LinkedinShareButton>
            </ShareText>
            <ShareIcons>
              <strong>Share:</strong>
              <RedditShareButton url={postUrl} title={props.title}>
                <RedditIcon size={size} bgStyle={{ fill: '#ff4500' }} />
              </RedditShareButton>
              <TwitterShareButton url={postUrl} title={props.title}>
                <TwitterIcon size={size} />
              </TwitterShareButton>
              <FacebookShareButton url={postUrl} quote={props.summary}>
                <FacebookIcon size={size} />
              </FacebookShareButton>
              <LinkedinShareButton
                url={postUrl}
                title={props.title}
                summary={props.summary}
                source={data.site.siteMetadata.title}
              >
                <LinkedinIcon size={size} />
              </LinkedinShareButton>
            </ShareIcons>
            <Frame>
              <Title>
                <Link title="Home" to="/">
                  {data.site.siteMetadata.title}
                </Link>
              </Title>
              <Content>
                <Portrait src={portraitURL} />
                <Info>
                  <p>
                    Personal blog of
                    <Link title="About" to="/about">
                      {data.site.siteMetadata.author}
                    </Link>
                    .
                  </p>
                  <p className="descriptionLong">
                    {data.site.siteMetadata.description}
                  </p>
                  <p className="descriptionShort">
                    He lives a developer's life.
                  </p>
                </Info>
                <TagList>
                  <h4>See more:</h4>
                  {tags}
                </TagList>
              </Content>
            </Frame>
          </Wrapper>
        );
      }}
    />
  );
};

export default SocialFooter;

SocialFooter.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
};
